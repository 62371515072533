import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

// API types
// Partial result from
// https://scenes.documents.cimpress.io/swagger/index.html
interface HalAssetLinkHalQueryResult {
    _embedded: HalAssetLinkHalEmbeddedCollection;
}

interface HalAssetLinkHalEmbeddedCollection {
    item: HalAssetLink[];
}

interface HalAssetLink {
    _links: HalAssetLinkLinks;
}

interface HalAssetLinkLinks {
    self: HalLink;
    content: HalLink;
    variableConfigurationContent: HalLink;
}

interface HalLink {
    href: string | null;
}

interface InterrogationResponse {
    Width: number;
    Height: number;
    Slots: Slot[];
}

interface Slot {
    Id: string;
    Page: number;
}

interface ProductResponse {
    name: string;
}

export const getPhotoSceneUrl = async (surfaceUrl: string, signal?: AbortSignal): Promise<string | null> => {
    try {
        const surfaceUrlObj = new URL(surfaceUrl);
        const mcpSku = surfaceUrlObj.pathname.split('/').pop();
        const productOptions = surfaceUrlObj.searchParams.get('variables');
        const productOptionsObj = productOptions ? JSON.parse(productOptions) : undefined;
        const requestBody = {
            referenceId: mcpSku,
            Tenant: { type: 'merchants', id: 'vistaprint-prod' },
            attributes: productOptionsObj,
            assetTypes: ['scene'],
        } as const;
        const linksQueryUrl = 'https://scenes.documents.cimpress.io/v3/assets/-/links:query';
        const result = await axios.post<HalAssetLinkHalQueryResult>(linksQueryUrl, requestBody, {
            headers: AUTHORIZATION_HEADERS,
            signal,
        });
        const scenes = result?.data?._embedded?.item;
        const sceneForFirstPage = !!scenes && scenes.length > 0 ? await getSceneForFirstPage(scenes, signal) : null;
        return sceneForFirstPage;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        console.error(`An error occurred while fetching photo scene URL: ${error}`);
        return null;
    }
};

async function getSceneForFirstPage(sceneUrls: HalAssetLink[], signal?: AbortSignal): Promise<string | null> {
    const allScenes = await Promise.all(
        sceneUrls.map(async (sceneUrl) => {
            try {
                if (!sceneUrl?._links?.content?.href) return null;
                const interrogationUrl = `https://assets.documents.cimpress.io/v1/interrogation?sceneUri=${sceneUrl._links.content.href}`;
                const response = await axios.get<InterrogationResponse>(interrogationUrl, {
                    headers: AUTHORIZATION_HEADERS,
                    signal,
                });
                return { url: sceneUrl._links.content.href, data: response.data };
            } catch (exception) {
                if (signal?.aborted) {
                    throw exception;
                }

                return null;
            }
        }),
    );
    const firstPageSceneData = allScenes.find((sceneData) => sceneData && sceneData.data?.Slots[0]?.Page === 1);
    return firstPageSceneData?.url ?? null;
}

// API Documentation
// https://developer.cimpress.io/software-catalog/component/api/bc8d6c62-a6a0-50a9-ba72-fc883798972d/openapi?env=production
export async function getProductNameBasedOnSku(mcpSku: string, signal?: AbortSignal): Promise<string | null> {
    const productUrl = `https://api.products.cimpress.io/v1/products/${mcpSku}:current`;
    try {
        const response = await axios.get<ProductResponse>(productUrl, {
            headers: AUTHORIZATION_HEADERS,
            signal,
        });
        return response.data?.name;
    } catch (error) {
        if (signal?.aborted) {
            throw error;
        }

        return null;
    }
}
