import { useEffect, useState, type CSSProperties } from 'react';

export interface AnchorImageProps {
    documentUrl?: string;
    previewUrl: string;
    alt: string;
    previewUrlOnHover?: string;
    useLoadingTransition?: boolean;
}
const ANCHOR_IMAGE_STYLE: CSSProperties = {
    maxWidth: '100%',
    minWidth: '10rem',
    transition: 'opacity 0.5s ease-in-out',
};
const LOADING_IMAGE_STYLE: CSSProperties = { ...ANCHOR_IMAGE_STYLE, opacity: 0.2 };

export const AnchorImage = ({
    documentUrl = '',
    previewUrl,
    previewUrlOnHover,
    alt,
    useLoadingTransition = false,
}: AnchorImageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isMouseHovering, setIsMouseHovering] = useState(false);

    useEffect(() => {
        setIsLoading(true);
    }, [previewUrl, isMouseHovering]);

    const handleLoad = () => setIsLoading(false);

    function handleMouseEnter() {
        if (previewUrlOnHover) {
            setIsMouseHovering(true);
        }
    }
    function handleMouseLeave() {
        if (previewUrlOnHover) {
            setIsMouseHovering(false);
        }
    }

    return (
        <a href={documentUrl} target="_blank" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <img
                src={previewUrlOnHover && isMouseHovering ? previewUrlOnHover : previewUrl}
                alt={alt}
                style={isLoading && useLoadingTransition ? LOADING_IMAGE_STYLE : ANCHOR_IMAGE_STYLE}
                onLoad={handleLoad}
            />
        </a>
    );
};
