import { Select } from '@cimpress/react-components';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ApiKeyContext } from '../App';
import { CardBuilder, Control, Page, PreviewRow, PreviewRows, PreviewSection, SurfaceSelector } from '../Components';
import { ComposeControls, ComposePresetSelector, PRIORITY_OPTIONS, useCompositeParams } from '../Components/Composite';
import { type Card, CustomSelector } from '../Components/common';
import { useSampleDocuments } from '../Components/common/useSampleDocuments';
import { getComposeUrl } from '../api';
import presetSurfaces from '../data/generateSurfaceJson.json';
import presetSurfaceUrls from '../data/generateSurfaceUrls.json';
import { isUrl } from '../tools';

function generateRefreshKey() {
    return uuidv4();
}

export const CompositePage = () => {
    const documents = useSampleDocuments('composite_source_documents.json');

    const {
        targetDocumentUrl,
        components,
        priority,
        surfaceSpecificationUrl,
        surfaceSpecification,
        setTargetDocumentUrl,
        setComponents,
        setPriority,
        setSurfaceSpecificationUrl,
        setSurfaceSpecification,
    } = useCompositeParams();
    const [refreshKey, setRefreshKey] = useState<string>(generateRefreshKey);
    const [targetDocumentRow, setTargetDocumentRow] = useState<Card[]>([]);
    const [resultDocumentRow, setResultDocumentRow] = useState<Card[]>([]);
    const apiKey = useContext(ApiKeyContext);

    const setDocumentUrl = (docUrl: string) => {
        if (isUrl(docUrl)) {
            setTargetDocumentUrl(docUrl);
            return;
        }
    };

    useEffect(() => {
        if (targetDocumentUrl) {
            const targetCard = CardBuilder.ofType('documentUrl')
                .withTitle('Target Document')
                .withData({ documentUrl: targetDocumentUrl })
                .build();
            setTargetDocumentRow(PreviewRow(targetCard));
        } else {
            setTargetDocumentRow(PreviewRow());
        }
    }, [targetDocumentUrl]);

    useEffect(() => {
        if (targetDocumentUrl && components) {
            const resultCard = CardBuilder.ofType('documentUrl')
                .withTitle('Result Document')
                .withData({
                    documentUrl:
                        getComposeUrl({
                            documentUrl: targetDocumentUrl,
                            assets: components,
                            apiKey,
                            priority: priority.value,
                            surfaceSpecificationUrl,
                            surfaceSpecification,
                        }) ?? '',
                    backgroundColor: 'FFFFFF',
                })
                .build();
            setResultDocumentRow(PreviewRow(resultCard));
        } else {
            setResultDocumentRow(PreviewRow());
        }
    }, [targetDocumentUrl, components, apiKey, priority, surfaceSpecificationUrl, surfaceSpecification]);

    return (
        <Page>
            <Page.Title>Compose</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Layout</Control.Title>
                    <CustomSelector
                        selectorLabel={'Document (or template)'}
                        textfieldLabel={'Document URL, Template URL '}
                        data={documents}
                        value={targetDocumentUrl}
                        setValue={setDocumentUrl}
                        validator={isUrl}
                    />
                </Control>

                <Control>
                    <Control.Title>Priotity</Control.Title>
                    <Select label="priority" value={priority} onChange={setPriority} options={PRIORITY_OPTIONS} />
                </Control>

                <ComposePresetSelector
                    onSelect={(components) => {
                        setComponents(components);
                        setRefreshKey(generateRefreshKey());
                    }}
                />

                <ComposeControls
                    // https://legacy.reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                    key={refreshKey}
                    initialComponents={components}
                    setComponents={setComponents}
                />

                <Control>
                    <Control.Title>Surface Selection</Control.Title>
                    <p>
                        A surface specification should be provided to avoid any unwanted update in a layout that
                        contains folding guides.
                    </p>
                    <SurfaceSelector
                        surfaceSpecificationUrl={surfaceSpecificationUrl}
                        setSurfaceSpecificationUrl={setSurfaceSpecificationUrl}
                        surfaceSpecification={surfaceSpecification}
                        setSurfaceSpecification={setSurfaceSpecification}
                        presetSurfaceUrlsFromPage={presetSurfaceUrls}
                        presetSurfaceJsonFromPage={presetSurfaces}
                    />
                </Control>
            </Page.Controls>

            <Page.Content>
                <PreviewSection
                    introduction={
                        'This is a page to test the compose endpoint. Fill in the target and assets to see the composed result.'
                    }
                    showIntro={!targetDocumentUrl || !components}
                    rows={PreviewRows(targetDocumentRow, resultDocumentRow)}
                />
            </Page.Content>
        </Page>
    );
};
