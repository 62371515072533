import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import type { SelectorOption } from '../common';

const MODEL_OPTIONS: SelectorOption[] = [
    { label: 'Basic', value: 'basic' },
    { label: 'Standard', value: 'standard' },
    { label: 'Premium', value: 'premium' },
];

const ASPECT_RATIO_OPTIONS: SelectorOption[] = [
    { label: 'Square', value: 'square' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
];
const MEDIA_TYPE_OPTIONS: SelectorOption[] = [
    { label: 'Raster', value: 'raster' },
    { label: 'Vector', value: 'vector' },
];

const CONTENT_TYPE_OPTIONS: SelectorOption[] = [
    { label: 'Photo', value: 'photo' },
    { label: 'Clipart', value: 'clipart' },
    { label: 'Single Color Clipart', value: 'singleColorClipart' },
];

const IMAGE_COUNT_OPTION: SelectorOption[] = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '4', value: '4' },
    { label: '8', value: '8' },
    { label: '16', value: '16' },
];
interface UseGenerateImageFromTextParams {
    prompt: string | null;
    modelSelectedOption: SelectorOption;
    mediaTypeSelectedOption: SelectorOption;
    contentTypeSelectedOption: SelectorOption;
    aspectRatioSelectedOption: SelectorOption;
    imageCountSelectedOption: SelectorOption;
    setPrompt: (prompt: string) => void;
    setModelSelectedOption: (modelOption: SelectorOption) => void;
    setMediaTypeSelectedOption: (mediaTypeSelectedOption: SelectorOption) => void;
    setContentTypeSelectedOption: (contentTypeSelectedOption: SelectorOption) => void;
    setAspectRatioSelectedOption: (aspectRatioSelectedOption: SelectorOption) => void;
    setImageCountSelectedOption: (imageCountSelectedOption: SelectorOption) => void;
}

export const useGenerateImageFromTextParams = (): UseGenerateImageFromTextParams => {
    const [searchParams, setSearchParams] = useSearchParams();

    const promptParam = searchParams.get('prompt');
    const modelParam = searchParams.get('modelSelectedOption');
    const mediaTypeParam = searchParams.get('mediaTypeSelectedOption');
    const contentTypeParam = searchParams.get('contentTypeSelectedOption');
    const aspectRatioParam = searchParams.get('aspectRatioSelectedOption');
    const imageCountParam = searchParams.get('imageCountSelectedOption');

    const [prompt, setPrompt] = useState(promptParam ?? '');
    const [modelSelectedOption, setModelSelectedOption] = useState<SelectorOption>(
        modelParam ? MODEL_OPTIONS.find((option) => option.value === modelParam) || MODEL_OPTIONS[0] : MODEL_OPTIONS[0],
    );
    const [aspectRatioSelectedOption, setAspectRatioSelectedOption] = useState<SelectorOption>(
        aspectRatioParam
            ? ASPECT_RATIO_OPTIONS.find((option) => option.value === aspectRatioParam) || ASPECT_RATIO_OPTIONS[0]
            : ASPECT_RATIO_OPTIONS[0],
    );

    const [mediaTypeSelectedOption, setMediaTypeSelectedOption] = useState<SelectorOption>(
        mediaTypeParam
            ? MEDIA_TYPE_OPTIONS.find((option) => option.value === mediaTypeParam) || MEDIA_TYPE_OPTIONS[0]
            : MEDIA_TYPE_OPTIONS[0],
    );

    const [contentTypeSelectedOption, setContentTypeSelectedOption] = useState<SelectorOption>(
        contentTypeParam
            ? CONTENT_TYPE_OPTIONS.find((option) => option.value === contentTypeParam) || CONTENT_TYPE_OPTIONS[0]
            : CONTENT_TYPE_OPTIONS[0],
    );

    const [imageCountSelectedOption, setImageCountSelectedOption] = useState<SelectorOption>(
        imageCountParam
            ? IMAGE_COUNT_OPTION.find((option) => option.value === imageCountParam) || IMAGE_COUNT_OPTION[0]
            : IMAGE_COUNT_OPTION[0],
    );

    useEffect(() => {
        const searchParamsObj: Record<string, string | string[]> = {};

        if (prompt) {
            searchParamsObj['prompt'] = prompt;
        }

        if (modelSelectedOption.value) {
            searchParamsObj['modelSelectedOption'] = modelSelectedOption.value;
        }

        if (mediaTypeSelectedOption.value) {
            searchParamsObj['mediaTypeSelectedOption'] = mediaTypeSelectedOption.value;
        }

        if (contentTypeSelectedOption.value) {
            searchParamsObj['contentTypeSelectedOption'] = contentTypeSelectedOption.value;
        }

        if (aspectRatioSelectedOption.value) {
            searchParamsObj['aspectRatioSelectedOption'] = aspectRatioSelectedOption.value;
        }

        if (imageCountSelectedOption.value) {
            searchParamsObj['imageCountSelectedOption'] = imageCountSelectedOption.value;
        }

        setSearchParams(searchParamsObj);
    }, [
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        mediaTypeSelectedOption,
        contentTypeSelectedOption,
        imageCountSelectedOption,
        setSearchParams,
    ]);

    return {
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        mediaTypeSelectedOption,
        contentTypeSelectedOption,
        imageCountSelectedOption,
        setPrompt,
        setModelSelectedOption,
        setAspectRatioSelectedOption,
        setMediaTypeSelectedOption,
        setContentTypeSelectedOption,
        setImageCountSelectedOption,
    };
};
