import { Button, Select, Spinner, TextField } from '@cimpress/react-components';
import { useContext, useState } from 'react';
import {
    CardBuilder,
    Content,
    Control,
    Page,
    PreviewRow,
    PreviewRows,
    PreviewSection,
    type Card,
    type SelectorOption,
} from '../Components';
import { useCancelableEffect } from '../tools';
import { ApiKeyContext } from '../App';
import { getImageFromTextUrl } from '../api';
import { useGenerateImageFromTextParams } from '../Components/GenerateImageFromText/useGenerateImageFromText';

const modelOptions = [
    {
        label: 'Basic',
        value: 'basic',
    },
    {
        label: 'Standard',
        value: 'standard',
    },
    {
        label: 'Premium',
        value: 'premium',
    },
];

const mediaTypeOptions = [
    { label: 'Raster', value: 'raster' },
    { label: 'Vector', value: 'vector' },
];

const contentTypeOptions = [
    { label: 'Photo', value: 'photo' },
    { label: 'Clipart', value: 'clipart' },
    { label: 'Single Color Clipart', value: 'singleColorClipart' },
];

const aspectRatioOptions = [
    { label: 'Square', value: 'square' },
    { label: 'Vertical', value: 'vertical' },
    { label: 'Horizontal', value: 'horizontal' },
];

const imageCountOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '4', value: '4' },
    { label: '8', value: '8' },
    { label: '16', value: '16' },
];

export const GenerateImageFromText = () => {
    const {
        prompt,
        modelSelectedOption,
        aspectRatioSelectedOption,
        mediaTypeSelectedOption,
        contentTypeSelectedOption,
        imageCountSelectedOption,
        setPrompt,
        setModelSelectedOption,
        setAspectRatioSelectedOption,
        setMediaTypeSelectedOption,
        setContentTypeSelectedOption,
        setImageCountSelectedOption,
    } = useGenerateImageFromTextParams();

    const [resultRow, setResultRow] = useState(PreviewRow());
    const [isLoading, setIsLoading] = useState(false);
    const [callGenerate, setCallGenerate] = useState(false);
    const showAspectRatio = mediaTypeSelectedOption.value !== 'vector';
    const apiKey = useContext(ApiKeyContext);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (callGenerate && prompt) {
                    setIsLoading(true);

                    const imageResultCards: Card[] = [];

                    const resultImagePromises: Promise<string>[] = [];

                    for (let i = 0; i < Number(imageCountSelectedOption.value); i++) {
                        const resultImagePromise = getImageFromTextUrl({
                            prompt: prompt,
                            modelSelectedOption: modelSelectedOption.value,
                            mediaTypeSelectedOption: mediaTypeSelectedOption.value,
                            contentTypeSelectedOption: contentTypeSelectedOption.value,
                            aspectRatioSelectedOption: aspectRatioSelectedOption.value,
                            signal,
                            apiKey,
                        });

                        resultImagePromises.push(resultImagePromise);
                    }

                    // Wait for all promises to settle
                    const resultImageUrls = await Promise.allSettled(resultImagePromises);

                    // Build the cards using resolved image URLs
                    for (const resultImageUrl of resultImageUrls) {
                        let imageResultCard;
                        if (resultImageUrl.status === 'fulfilled') {
                            imageResultCard = CardBuilder.ofType('imageUrl')
                                .withTitle(`Image`)
                                .withData({ imageUrl: resultImageUrl.value })
                                .build();
                        } else {
                            imageResultCard = CardBuilder.ofType('documentUrl')
                                .withTitle('Result Document')
                                .withLoader(async () => {
                                    throw resultImageUrl.reason;
                                })
                                .build();
                        }

                        imageResultCards.push(imageResultCard);
                        setResultRow(PreviewRow(...imageResultCards));
                    }

                    setIsLoading(false);
                    setCallGenerate(false);
                }
            })();
        },
        [
            callGenerate,
            prompt,
            apiKey,
            modelSelectedOption.value,
            aspectRatioSelectedOption.value,
            mediaTypeSelectedOption.value,
            contentTypeSelectedOption.value,
            imageCountSelectedOption.value,
        ],
    );

    const onModelChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setModelSelectedOption(selected);
    };

    const onMediaTypeChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setMediaTypeSelectedOption(selected);
    };

    const onContentTypeChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setContentTypeSelectedOption(selected);
    };

    const onAspectRatioChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setAspectRatioSelectedOption(selected);
    };

    const onImageCountChange = (selected: SelectorOption | null | undefined) => {
        if (!selected) {
            return;
        }
        setImageCountSelectedOption(selected);
    };

    function onClickGenerate() {
        setCallGenerate(true);
    }

    return (
        <Page>
            <Page.Title>Generate Images from Text</Page.Title>

            <Page.Controls>
                <Control>
                    <Control.Title>Prompt</Control.Title>
                    <TextField label="Prompt" onChange={(e) => setPrompt(e.target.value)} value={prompt ?? ''} />
                </Control>

                <Control>
                    <Control.Title>Model</Control.Title>
                    <Select onChange={onModelChange} value={modelSelectedOption} options={modelOptions} label="Model" />
                </Control>
                <Control>
                    <Control.Title>Media Type</Control.Title>
                    <Select
                        onChange={onMediaTypeChange}
                        value={mediaTypeSelectedOption}
                        options={mediaTypeOptions}
                        label="Media Type"
                    />
                </Control>
                <Control>
                    <Control.Title>Content Type</Control.Title>
                    <Select
                        onChange={onContentTypeChange}
                        value={contentTypeSelectedOption}
                        options={contentTypeOptions}
                        label="Content Type"
                    />
                </Control>
                {showAspectRatio ? (
                    <Control>
                        <Control.Title>Aspect Ratio</Control.Title>
                        <Select
                            onChange={onAspectRatioChange}
                            value={aspectRatioSelectedOption}
                            options={aspectRatioOptions}
                            label="Aspect Ratio"
                        />
                    </Control>
                ) : null}
                <Control>
                    <Control.Title>Image Count</Control.Title>
                    <Select
                        onChange={onImageCountChange}
                        value={imageCountSelectedOption}
                        options={imageCountOptions}
                        label="Image Count"
                    />
                </Control>
                <Control>
                    <Button onClick={onClickGenerate}>Generate</Button>
                </Control>
            </Page.Controls>

            <Page.Content>
                {isLoading ? (
                    <Content.Card sticky size="large">
                        <Content.Section>
                            <Spinner />
                        </Content.Section>
                    </Content.Card>
                ) : (
                    <PreviewSection
                        introduction={
                            'This is a page to test the Generate Image From Text endpoint. Fill in the prompt to see generated results.'
                        }
                        showIntro={!prompt}
                        rows={PreviewRows(resultRow)}
                    />
                )}
            </Page.Content>
        </Page>
    );
};

export default GenerateImageFromText;
